// Start Active Storage
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

//Start Rails UJS
import Rails from "@rails/ujs"
if (!window._rails_loaded) Rails.start()

import "@hotwired/turbo-rails"
import "trix"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

window.Stimulus = Application.start()
const controllers = import.meta.glob("../controllers/**/*_controller.js", {
  eager: true
})
registerControllers(window.Stimulus, controllers)

/* Register global callback for unauthorized AJAX requests
 * since devise invalidates sessions after a time (via timeoutable module).
 * This behaviour can be tested by setting the timeout to 10.seconds in the devise config.
 * This callback reloads the page whenever an AJAX request returns 401.
 */
document.documentElement.addEventListener("turbo:submit-end", (event) => {
  const { success, fetchResponse } = event.detail
  if (!success && fetchResponse.response.statusText == "Unauthorized") {
    location.reload()
  }
})
